<template>
  <div class="NotFound404">
    {{ messages.notFound }}
  </div>
</template>

<script>
export default {
  name: 'NotFound404',
  data() {
    return {
      messages: {
        notFound: this.$t('errorViews.notFound404.notFound')
      }
    };
  },
};
</script>
